import {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { lazyImport } from "../utils/lazyImport";
import { MainLayout } from "../components/Layout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { getColleagueAlerts } from "features/colleague-portal/myInbox/api/colleagueAlerts";
import { ColleagueAlert } from "../features/colleague-portal/myInbox";
import { addDays, format, nextSaturday, previousSunday } from "date-fns";
import { AxiosError } from "axios";
import { searchShifts } from "features/colleague-portal/myPay/api/MyShifts";
import { ShiftSearchData } from "features/colleague-portal/myPay/types";
import { Webchat } from "components/Chat/Webchat";
import { getSiteOptions } from "features/colleague-portal/myJob/api/getSites";
import storage from "utils/storage";
import { getColleagueDetails } from "features/colleague-portal/aboutMe/api/getColleagueDetails";
import { Colleague } from "../features/colleague-portal/aboutMe";
import Notifications from "components/Notifications/Notifications";
import { useOnlineStatus } from "hooks/connectionStatus";
import RGA from "react-ga4";
import { SiteAvailability } from "features/colleague-portal/myJob/types";
import { useGlobalLoading } from "hooks/globalLoaderContext";

const { AboutMePage } = lazyImport(
  () => import("../features/colleague-portal/aboutMe"),
  "AboutMePage"
);

const { HomePage } = lazyImport(
  () => import("../features/colleague-portal/landing"),
  "HomePage"
);

const { LandingVideoPage } = lazyImport(
  () => import("../features/colleague-portal/landing"),
  "LandingVideoPage"
);

const { MyPlannerPage } = lazyImport(
  () => import("../features/colleague-portal/myPlanner"),
  "MyPlannerPage"
);
const { AccountSettingsPage } = lazyImport(
  () => import("../features/colleague-portal/accountSettings"),
  "AccountSettingsPage"
);

const { HelpPage } = lazyImport(
  () => import("../features/colleague-portal/help"),
  "HelpPage"
);

const { MyDocumentsPage } = lazyImport(
  () => import("../features/colleague-portal/myDocuments"),
  "MyDocumentsPage"
);

const { MyJobPage } = lazyImport(
  () => import("../features/colleague-portal/myJob"),
  "MyJobPage"
);

const { MyPayPage } = lazyImport(
  () => import("../features/colleague-portal/myPay"),
  "MyPayPage"
);

const { ReportAbsencePage } = lazyImport(
  () => import("../features/colleague-portal/reportAbsence"),
  "ReportAbsencePage"
);

const { MyInboxPage } = lazyImport(
  () => import("../features/colleague-portal/myInbox"),
  "MyInboxPage"
);

export const NotificationsCountContext = createContext<{
  notifications: ColleagueAlert[];
  setNotifications: React.Dispatch<React.SetStateAction<ColleagueAlert[]>>;
}>({
  notifications: [],
  setNotifications: () => {},
});

export const NextWeekShiftsContext = createContext<{
  upcomingWeekShifts: ShiftSearchData[];
  setUpcomingWeekShifts: React.Dispatch<
    React.SetStateAction<ShiftSearchData[]>
  >;
}>({
  upcomingWeekShifts: [],
  setUpcomingWeekShifts: () => {},
});

export const ColleagueDetailsContext = createContext<{
  colleagueDetails: Colleague;
  setColleagueDetails: React.Dispatch<React.SetStateAction<Colleague>>;
}>({
  colleagueDetails: {},
  setColleagueDetails: () => {},
});

export const GlobalLoadingContext = createContext<{
  globalLoading: boolean;
  setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  globalLoading: false,
  setGlobalLoading: () => false,
});
const Main = () => {
  const [notifications, setNotifications] = useState<ColleagueAlert[]>([]);
  const [upcomingWeekShifts, setUpcomingWeekShifts] = useState<
    ShiftSearchData[]
  >([]);
  const [colleagueDetails, setColleagueDetails] = useState<Colleague>({
    forename: "",
    dob: "",
    name: "",
    emailAddress: "",
  });
  const isOnline = useOnlineStatus();
  const navigate = useNavigate();
  const { globalLoading, setGlobalLoading } = useGlobalLoading();

  let location = useLocation();
  const getNextSaturday = nextSaturday(new Date());
  const lastSunday = previousSunday(new Date());

  const fetchColleagueAlerts = async () => {
    try {
      const response = await getColleagueAlerts();
      setNotifications(response?.colleaguePortalAlert || []);
    } catch (error) {
      console.error("Error fetching colleague details:", error);
    }
  };

  const getWeekShifts = async () => {
    const getNextNextSaturday = addDays(new Date(getNextSaturday), 7);

    try {
      const shiftsResponse = await searchShifts({
        startDate: format(lastSunday, "yyyy-MM-dd"),
        endDate: format(getNextNextSaturday, "yyyy-MM-dd"),
      });
      setUpcomingWeekShifts(shiftsResponse?.data || []);
      return shiftsResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        // resetState();
        // setError(error.response?.data.notes);
        console.log(error);
      }
    }
  };

  const fetchColleagueDetails = async () => {
    try {
      const response = await getColleagueDetails();
      setColleagueDetails(() => ({
        dob: response.colleague.dob,
        forename: response.colleague?.forename,
        emailAddress: response.colleague.emailAddress,
        name: response.colleague.name,
        firstLoginStatus: response.colleague.firstLoginStatus,
      }));
      const isFirstLogin = response.colleague.firstLoginStatus;
      if (!isFirstLogin) {
        setGlobalLoading(true);
        setTimeout(() => {
          navigate("/main/landingVideo");
        }, 1000);
      }
    } catch (error) {
      console.error("Error fetching colleague details:", error);
    }
  };

  const setSiteOptions = async () => {
    try {
      const response = await getSiteOptions();

      if (!response) {
        return;
      }
      let bookingType =
        response.colleaguePrimarySiteOptions.driverMainBase.depot.holidayOption;

      const siteAvailability =
        response.colleaguePrimarySiteOptions.driverMainBase.depot
          .siteAvailability;
      const siteAvailabilityNoticePeriod =
        response.colleaguePrimarySiteOptions.driverMainBase.depot
          .siteAvailabilityNoticePeriod;

      const siteAvailabilityTimeList =
        response.colleaguePrimarySiteOptions?.siteAvailabilityTimeList;

      const siteAvailabilityOptions: SiteAvailability = {
        siteAvailabilityTimeList,
        siteAvailability,
        siteAvailabilityNoticePeriod,
      };

      switch (bookingType) {
        case "hourly":
          bookingType = "hourly";
          break;
        case "half-daily":
          bookingType = "half_day";
          break;
        default:
          bookingType = "full_day";
          break;
      }
      storage.setBookingType(bookingType);
      storage.setSiteAvailability(siteAvailabilityOptions);
      return bookingType;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSiteOptions();
    getWeekShifts();
    fetchColleagueDetails();
  }, []);

  useEffect(() => {
    RGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
    if (location.pathname.includes("myInbox")) {
      getWeekShifts();
    }
    fetchColleagueAlerts();
  }, [location]);

  return (
    <NotificationsCountContext.Provider
      value={{ notifications, setNotifications }}
    >
      <NextWeekShiftsContext.Provider
        value={{ upcomingWeekShifts, setUpcomingWeekShifts }}
      >
        <ColleagueDetailsContext.Provider
          value={{ colleagueDetails, setColleagueDetails }}
        >
          <MainLayout>
            {!isOnline ? (
              <h2 className="flex text-center justify-center">
                {" "}
                You are currently offline
              </h2>
            ) : null}
            <Suspense
              fallback={
                <div className="h-full w-full flex items-center justify-center">
                  <CircularProgress size="xl" color="primary" />
                </div>
              }
            >
              {globalLoading ? (
                <Box
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent background
                    zIndex: 1300, // Ensure it's above other elements
                  }}
                >
                  <CircularProgress color="primary" size={60} />{" "}
                </Box>
              ) : null}
              <Outlet />
              <Notifications />
              <Webchat />

              {/* <WebchatV2 /> */}
            </Suspense>
          </MainLayout>
        </ColleagueDetailsContext.Provider>
      </NextWeekShiftsContext.Provider>
    </NotificationsCountContext.Provider>
  );
};

export const protectedRoutes = [
  {
    path: "/main",
    element: <Main />,
    children: [
      { path: "aboutMe", element: <AboutMePage /> },
      { path: "myJob", element: <MyJobPage /> },
      { path: "myPlanner", element: <MyPlannerPage /> },
      { path: "myPay", element: <MyPayPage /> },
      { path: "myDocuments", element: <MyDocumentsPage /> },
      { path: "reportAbsence", element: <ReportAbsencePage /> },
      { path: "help", element: <HelpPage /> },
      { path: "myInbox", element: <MyInboxPage /> },
      { path: "accountSettings", element: <AccountSettingsPage /> },
      { path: "landingVideo", element: <LandingVideoPage /> },
      { path: "*", element: <HomePage /> },
    ],
  },
];
